[dir] .card-statistics .statistics-body[data-v-7b396258] {
  padding: 2rem 2.4rem !important;
}
@media (max-width: 991.98px) {
[dir] .card-statistics .card-header[data-v-7b396258], [dir] .card-statistics .statistics-body[data-v-7b396258] {
    padding: 1.5rem !important;
}
}
[dir] .card-company-table thead th[data-v-7b396258] {
  border: 0;
}
[dir] .card-company-table td[data-v-7b396258] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
[dir] .card-company-table td .avatar[data-v-7b396258] {
  background-color: #f8f8f8;
}
[dir=ltr] .card-company-table td .avatar[data-v-7b396258] {
  margin-right: 2rem;
}
[dir=rtl] .card-company-table td .avatar[data-v-7b396258] {
  margin-left: 2rem;
}
[dir] .card-company-table td .avatar img[data-v-7b396258] {
  border-radius: 0;
}
[dir] .card-browser-states .browser-states[data-v-7b396258]:first-child {
  margin-top: 0;
}
[dir] .card-browser-states .browser-states[data-v-7b396258]:not(:first-child) {
  margin-top: 1.7rem;
}
[dir] .card-transaction .transaction-item[data-v-7b396258]:not(:last-child) {
  margin-bottom: 1.5rem;
}
[dir] .card-company-table td .b-avatar.badge-light-company[data-v-7b396258] {
  background-color: #f8f8f8;
}
[dir=ltr] .card-company-table td .b-avatar.badge-light-company[data-v-7b396258] {
  margin-right: 2rem;
}
[dir=rtl] .card-company-table td .b-avatar.badge-light-company[data-v-7b396258] {
  margin-left: 2rem;
}
.apexcharts-canvas line[stroke=transparent][data-v-7b396258] {
  display: none;
}
[dir] .apexcharts-canvas .apexcharts-tooltip[data-v-7b396258] {
  background: #fff;
  border-color: #ebe9f1;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title[data-v-7b396258] {
  font-weight: 600;
}
[dir] .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title[data-v-7b396258] {
  background: #fff !important;
  border-color: #ebe9f1 !important;
}
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text-label[data-v-7b396258],
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text-value[data-v-7b396258] {
  color: #fff;
}
[dir] .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258], [dir] .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258] {
  background: #f8f8f8;
  border-color: #ebe9f1;
}
[dir] .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258]:after, [dir] .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258]:before, [dir] .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258]:after, [dir] .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258]:before {
  border-bottom-color: #f8f8f8;
}
.apexcharts-canvas .apexcharts-text[data-v-7b396258],
.apexcharts-canvas .apexcharts-tooltip-text[data-v-7b396258],
.apexcharts-canvas .apexcharts-datalabel-label[data-v-7b396258],
.apexcharts-canvas .apexcharts-datalabel[data-v-7b396258] {
  font-family: "Quicksand", sans-serif !important;
  fill: #6e6b7b;
  font-weight: 400;
  filter: none;
}
.apexcharts-canvas .apexcharts-pie-label[data-v-7b396258] {
  fill: white;
  filter: none;
}
.apexcharts-canvas .apexcharts-pie .apexcharts-pie-series .apexcharts-pie-area[data-v-7b396258] {
  stroke-width: 0;
}
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label[data-v-7b396258],
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value[data-v-7b396258] {
  font-size: 1.5rem;
}
[dir] .apexcharts-canvas .apexcharts-marker[data-v-7b396258] {
  box-shadow: none;
}
[dir] .apexcharts-canvas .apexcharts-legend-series + .apexcharts-legend-series[data-v-7b396258] {
  margin-top: 0.625rem;
}
.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text[data-v-7b396258] {
  color: #6e6b7b !important;
  font-size: 1rem !important;
}
[dir=ltr] .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text[data-v-7b396258] {
  margin-left: 0.5rem;
}
[dir=rtl] .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text[data-v-7b396258] {
  margin-right: 0.5rem;
}
.apexcharts-canvas .apexcharts-xcrosshairs[data-v-7b396258],
.apexcharts-canvas .apexcharts-ycrosshairs[data-v-7b396258],
.apexcharts-canvas .apexcharts-gridline[data-v-7b396258] {
  stroke: #ebe9f1;
}
.apexcharts-legend.position-bottom[data-v-7b396258] {
  bottom: 3rem;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxis-tick[data-v-7b396258],
.dark-layout .apexcharts-canvas line[data-v-7b396258] {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-heatmap .apexcharts-heatmap-rect[data-v-7b396258] {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-radialbar .apexcharts-radialbar-track .apexcharts-radialbar-area[data-v-7b396258] {
  stroke: #161d31;
}
.dark-layout .apexcharts-canvas .apexcharts-radar-series polygon[data-v-7b396258] {
  fill: #161d31;
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-datalabels-group .apexcharts-datalabel-value[data-v-7b396258] {
  fill: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-tooltip[data-v-7b396258] {
  background: #283046;
  border-color: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title[data-v-7b396258] {
  color: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title[data-v-7b396258] {
  background: #283046 !important;
  border-color: #3b4253 !important;
}
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-label[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-value[data-v-7b396258] {
  color: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258], [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258] {
  background: #161d31;
  border-color: #3b4253;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258]:after, [dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip[data-v-7b396258]:before, [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258]:after, [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip[data-v-7b396258]:before {
  border-bottom-color: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-yaxistooltip-text[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-xaxistooltip-text[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-yaxistooltip-text[data-v-7b396258] {
  color: #fff;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text[data-v-7b396258] {
  color: #fff;
}
.dark-layout .apexcharts-canvas .apexcharts-yaxis-label[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-xaxis-label[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-tooltip-text[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-datalabel-label[data-v-7b396258] {
  fill: #b4b7bd;
}
.dark-layout .apexcharts-canvas .apexcharts-marker[data-v-7b396258] {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text[data-v-7b396258] {
  color: #b4b7bd !important;
}
.dark-layout .apexcharts-canvas .apexcharts-xcrosshairs[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-ycrosshairs[data-v-7b396258],
.dark-layout .apexcharts-canvas .apexcharts-gridline[data-v-7b396258] {
  stroke: #3b4253;
}
.echarts[data-v-7b396258] {
  width: 100%;
}
.per-page-selector[data-v-7b396258] {
  width: 90px;
}
.swal2-icon-content i[data-v-7b396258] {
  font-size: 2rem;
  color: #28c76f;
}
[dir] .swal2-icon-show[data-v-7b396258] {
  border-color: #28c76f;
}